import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';

const BemutatoteremBudapest = () => (
  <Layout page="palyazatok">
    <article id="main">
      <header>
        <h2>Pályázatok</h2>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header
          className="major large-header"
          style={{
            textAlign: 'left',
          }}
        >
          <p>
            <b>Projekt azonosító száma:</b> GINOP-1.2.8-20-2020-01917
            <br style={{ display: 'block' }} />
            <b>Kedvezményezett neve:</b> JUHÁSZVIN Termelő, Kereskedelmi és
            Szolgáltató Kft.
            <br style={{ display: 'block' }} />
            <b>Projekt címe:</b> Technológiai megújulás a JUHÁSZVIN Kft.-nél
            <br style={{ display: 'block' }} />
            <b>Támogatás összege:</b> 49.406.070 Ft
            <br style={{ display: 'block' }} />
            <b>Támogatás mértéke:</b> 70%
            <br style={{ display: 'block' }} />
            <b>Projekt tényleges fizikai befejezési dátuma:</b> 2021.05.31.
          </p>
          <p>
            Projekt tartalmának bemutatása: A koronavírus gazdasági hatásai a
            cég életében is érezhetővé váltak, így a Kft. technológiai
            beruházást indított el, amely nemcsak a termelés, hanem a
            munkahelyek megtartását, további növekedését is lehetővé teszi. A
            termelékenység növelése érdekében a projekt megvalósítása során új
            eszközök beszerzésére került sor. Beszerzésre kerül 1 db RADIO
            SHUTTLE ÉS SOROS/STATIKUS állványrendszer, 1 db TOYOTA-BT STAXIO
            SWE140 típusú elektromos targonca és 1 db TOYOTA-BT REFLEX RRE120B
            típusú elektromos targonca. Az eszközök beszerzése a tevékenység
            minőségi és mennyiségi értéknek növelését támogatja, hiszen a
            korszerű, magas műszaki színvonalú eszközök üzembe helyezésével
            növekszik a rendelkezésükre álló eszközállomány minőségi
            összetétele. A gépek hatékony működésének köszönhetően az önköltség
            is csökken, növekszik a termelés hozzáadott- értéke, tehát javítja a
            technikai felkészültséget, versenyképességet és erőforrás-
            hatékonyságot, ezáltal a vállalkozásunk számára eredménynövekedést,
            új munkahelyek létrehozását és minél nagyobb arányú megtartását
            eredményez.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header
          className="major large-header"
          style={{
            textAlign: 'left',
          }}
        >
          <p>
            <b>Projekt azonosító száma:</b> GINOP-2.1.8-17-2017-00569
            <br style={{ display: 'block' }} />
            <b>Kedvezményezett neve:</b> JUHÁSZVIN Termelő, Kereskedelmi és
            Szolgáltató Kft.
            <br style={{ display: 'block' }} />
            <b>Projekt címe:</b> Adaptív technológiai innováció a Juhászvin
            Kft-nél
            <br style={{ display: 'block' }} />
            <b>Támogatás összege:</b> 14.645.300.- Ft
            <br style={{ display: 'block' }} />
            <b>Támogatás mértéke:</b> 50%
            <br style={{ display: 'block' }} />
            <b>Projekt tényleges fizikai befejezési dátuma:</b> 2018.10.15.
          </p>
          <p>
            Projekt tartalmának bemutatása: A projekt során beszerzésre került 1
            db Winescan Boranalizáló műszer és 1 db JUCLAS JRO 04 COMPACT
            mustsűrítő. Az eszközök beszerzésével új, a Juhászvin Kft-nél eddig
            nem használt adaptív technológia került bevezetésre, melynek
            eredményeként hatékonyabb munkavégzés, új termelési módszer
            alkalmazása jött létre. Jelen projekt keretében adaptív borászati
            technológiák kerülnek bevezetésre, ezáltal a fejlesztés közvetlenül
            illeszkedik a Nemzeti S3-ban meghatározott nemzeti vagy horizontális
            ágazati prioritások között meghatározott Agrár-innováció
            prioritáshoz. A vállalat piaci pozíciójának megőrzésének és
            javításának alapfeltétele a költséghatékony, magas minőséget
            eredményező gyártástechnológia alkalmazása. Jelen projekt esetében
            adaptív technológiai innovációs fejlesztést hajtottunk végre,
            melynek eredményeként képesek leszünk megrendelőink megnövekedett
            mennyiségi és minőségi igényeit kielégíteni. A fejlesztés
            eredményeként növekszik a vállalkozás technológiai felkészültsége,
            versenyképessége valamint az erőforrás-hatékonyság. A projekt
            hozzájárul stratégiai céljaink eléréséhez; növeli a gazdálkodás
            eredményeit és hozzájárul a tevékenység hosszú távú
            fenntarthatóságához, ezáltal lehetőséget teremt a munkahelyek
            megtartásához is.
          </p>
        </header>
      </div>
    </section>
    <StaticImage
      style={{
        position: 'fixed',
        right: '0',
        bottom: '0',
        width: '25%',
        minWidth: '300px',
        zIndex: 9,
      }}
      src="../../assets/images/infog2.png"
      alt=""
    />
  </Layout>
);

export default BemutatoteremBudapest;
